//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    dialog() {
      return this.$store.state.global.dialog ?? null
    },
    shareDialog() {
      return this.$store.state.global.share ?? null
    },
    dialogProps() {
      const props = this.$store.state.global.dialogProps
      if (!props) {
        return {}
      }
      return props
    },
    renderFormLocale() {
      return ['en_au', 'en_gb', 'de', 'pl', 'fr', 'nl'].includes(
        this.$i18n.locale
      )
        ? this.$i18n.locale
        : false
    },
  },
}
