//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return this.$route.meta.headerBack ?? {}
  },
  computed: {
    title() {
      return this.$translation('menu.' + this.$route.meta.name)
    },
    editProfileUrl() {
      return this.editButton
        ? '/user/' + this.$auth.user.nickname + '/edit-profile'
        : null
    },
    editButton() {
      return (
        this.showEditButton &&
        this.$auth.user &&
        this.$auth.user.nickname === this.$route.params.user
      )
    },
  },
  methods: {
    goBack() {
      if (this.$auth.user) {
        this.$router.push({
          path: this.$localeResolver.localePath(this.backUrl),
        })
      } else {
        this.$router.go(-1)
      }
    },
  },
}
