//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      required: true,
      type: Array,
    },
    subMenuItems: {
      required: false,
      type: Array,
      default() {},
    },
    text: {
      required: false,
      default: '',
      type: String,
    },
    headline: {
      required: false,
      default: '',
      type: String,
    },
    parentSlug: {
      required: true,
      default: '',
      type: String,
    },
  },

  methods: {
    submenuClick() {
      if (this.userBranch !== '') {
        // eslint-disable-next-line no-undef
        const currentPageURL = window.location.href
        // eslint-disable-next-line no-undef
        window._mtm = window._mtm || []
        // eslint-disable-next-line no-undef
        window._mtm.push({
          event: 'form-Process-interaction',
          'event-category': 'form-Process-interaction',
          'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
        })
      }
    },
    close(id) {
      this.$emit('submenu:close', id)
    },
    url(url) {
      return this.$localeResolver.localePath({
        path: this.parentSlug !== '' ? `/${this.parentSlug}/${url}` : `/${url}`,
      })
    },
  },
}
