//
//
//
//
//

export default {
  computed: {
    layer() {
      return this.$store.state.global.layer
    },
  },
}
