//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: 'Skip to',
    },
    links: {
      type: Array,
      default() {
        return [
          {
            anchor: '#main',
            label: 'Main Content',
          },
        ]
      },
    },
  },
}
