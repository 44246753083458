//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Areas from '~/utils/areas'
import getFooter from '~/gql/getFooter'
import getMenusWithArea from '~/gql/getMenusWithArea'

export default {
  name: 'LayoutFooter',
  props: {
    variant: {
      type: String,
      default: 'default',
    },
  },

  data() {
    return {
      sections: [],
      metaMenus: [],
      areas: {
        footer: Areas.FOOTER,
        copyright: Areas.COPYRIGHT,
      },
    }
  },

  async fetch() {
    // footer sections
    const footer = await this.$graphcms.request(getFooter, {
      area: [this.areas.footer],
      lang: [this.$i18n.locale, this.$i18n.defaultLocale],
    })

    this.sections =
      footer.sectionMenus && footer.sections
        ? [...footer.sectionMenus, ...footer.sections]
        : []
    this.sections.sort((a, b) => a.priority - b.priority)

    // meta menu
    const metaMenu = await this.$graphcms.request(getMenusWithArea, {
      area: [this.areas.copyright],
      lang: [this.$i18n.locale, this.$i18n.defaultLocale],
    })
    this.metaMenus = metaMenu.sectionMenus
  },
  computed: {
    showNewsletterBanner() {
      const state = this.$translation('newsletter.showNewsletterBanner')
      if (state.toLowerCase() === 'false' || this.variant === 'tiny')
        return false
      else if (['hear', 'horen'].includes(this.$route.params.slug)) return false

      return true
    },

    classList() {
      return {
        footer: true,
        [`footer--${this.variant}`]: this.variant !== 'default',
      }
    },
  },
  watch: {
    '$i18n.locale'() {
      this.$fetch()
    },
  },
  methods: {
    newsletter() {
      if (this.userBranch !== '') {
        // eslint-disable-next-line no-undef
        const currentPageURL = window.location.href
        // eslint-disable-next-line no-undef
        window._mtm = window._mtm || []
        // eslint-disable-next-line no-undef
        window._mtm.push({
          event: 'newsletter-sign-up-start',
          'event-category': 'newsletter-sign-up-start',
          'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
        })
      }
    },
  },
}
