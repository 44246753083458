//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LayoutHeader',
  props: {
    variant: {
      type: String,
      default: 'default',
    },
    hasHomeLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hasScrolled: false,
    }
  },

  computed: {
    classList() {
      return {
        header: true,
        [`header--${this.variant}`]: this.variant !== 'default',
        scrolled: this.hasScrolled,
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.hasScrolled = window.scrollY > 0
    },
  },
}
