//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  watch: {
    $route(to, from) {
      this.close()
    },
  },
  methods: {
    close() {
      this.$store.commit('global/setDrawer', {
        drawer: null,
        layer: false,
      })
    },
  },
}
