//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { API } from 'aws-amplify'
import userMixin from '~/mixins/user'
import forumApi from '~/utils/forumApi'
import Areas from '~/utils/areas'
import getMenusWithArea from '~/gql/getMenusWithArea'

export default {
  mixins: [userMixin],
  data() {
    return {
      notifications: [],
      userLinks: {
        signUp: null,
      },
    }
  },
  async fetch() {
    if (this.$auth.user && this.$store.state.user?.forumId) {
      const { results } = await API.get(
        'forum',
        `${forumApi.MEMBERS}/${this.$store.state.user?.forumId}${forumApi.NOTIFICATIONS}`
      )
      this.notifications = results
    }

    const menu = await this.$graphcms.request(getMenusWithArea, {
      area: [Areas.USERLINKS],
      lang: [this.$i18n.locale, this.$i18n.defaultLocale],
    })
    const { menuItems } = menu.sectionMenus[0]

    this.userLinks.signUp = menuItems[0]
  },
  computed: {
    messagesHref() {
      return `${process.env.FORUM_URL}${forumApi.MESSENGER}`
    },
    filteredNotifications() {
      const filteredNotifications = this.notifications.filter(
        (notification) => notification.notificationData.unread
      )
      return filteredNotifications
    },
  },
  watch: {
    '$i18n.locale'() {
      this.$fetch()
    },
  },
  methods: {
    showDrawer(name) {
      this.$store.commit('global/setDrawer', {
        drawer: name,
        layer: true,
      })
    },
    search() {
      if (this.userBranch !== '') {
        // eslint-disable-next-line no-undef
        const currentPageURL = window.location.href
        // eslint-disable-next-line no-undef
        window._mtm = window._mtm || []
        // eslint-disable-next-line no-undef
        window._mtm.push({
          event: 'site-search-tracking',
          'event-category': 'site-search-tracking',
          'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
        })
      }
    },
    login() {
      if (this.userBranch !== '') {
        // eslint-disable-next-line no-undef
        const currentPageURL = window.location.href
        // eslint-disable-next-line no-undef
        window._mtm = window._mtm || []
        // eslint-disable-next-line no-undef
        window._mtm.push({
          event: 'login-button-click',
          'event-category': 'button-click',
          'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
        })
      }

      this.$auth.login()
    },
    signup() {
      if (this.userBranch !== '') {
        // eslint-disable-next-line no-undef
        const currentPageURL = window.location.href
        // eslint-disable-next-line no-undef
        window._mtm = window._mtm || []
        // eslint-disable-next-line no-undef
        window._mtm.push({
          event: 'Signup-button-click',
          'event-category': 'button-click',
          'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
        })
      }
    },
    logout() {
      this.$auth.logout()
    },
  },
}
