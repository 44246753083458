//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Areas from '~/utils/areas'
import getMenusWithArea from '~/gql/getMenusWithArea'
import userMixin from '~/mixins/user'

export default {
  mixins: [userMixin],
  data() {
    return {
      userBranch: process.env.USER_BRANCH,
      menuItemsMeta: [],
      menuItemsMain: [],
      userLinks: {
        signUp: null,
      },
    }
  },
  async fetch() {
    const { sectionMenus } = await this.$graphcms.request(getMenusWithArea, {
      area: [Areas.BURGERMAIN, Areas.BURGERMETA],
      lang: [this.$i18n.locale, this.$i18n.defaultLocale],
    })
    this.menuItemsMeta = sectionMenus[0].menuItems
    this.menuItemsMain = sectionMenus[1].menuItems

    const menu = await this.$graphcms.request(getMenusWithArea, {
      area: [Areas.USERLINKS],
      lang: [this.$i18n.locale, this.$i18n.defaultLocale],
    })
    const { menuItems } = menu.sectionMenus[0]

    this.userLinks.signUp = menuItems[0]
  },

  computed: {
    menuItemsUser() {
      return [...this.userProfileMenuItems, ...this.userSettingsMenuItems]
    },
    theme() {
      return this.$store.state.global.theme
    },
  },

  watch: {
    $route(to, from) {
      this.close()
    },
  },

  methods: {
    close() {
      this.$store.commit('global/setDrawer', {
        drawer: null,
        layer: false,
      })
    },
    login() {
      if (this.userBranch !== '') {
        // eslint-disable-next-line no-undef
        const currentPageURL = window.location.href
        // eslint-disable-next-line no-undef
        window._mtm = window._mtm || []
        // eslint-disable-next-line no-undef
        window._mtm.push({
          event: 'login-button-click',
          'event-category': 'button-click',
          'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
        })
      }

      this.$auth.loginWith('auth0')
    },
    signup() {
      if (this.userBranch !== '') {
        // eslint-disable-next-line no-undef
        const currentPageURL = window.location.href
        // eslint-disable-next-line no-undef
        window._mtm = window._mtm || []
        // eslint-disable-next-line no-undef
        window._mtm.push({
          event: 'Signup-button-click',
          'event-category': 'button-click',
          'event-action': `CLICK_LINK_URL - ${currentPageURL}`,
        })
      }
    },
    logout() {
      this.$auth.logout()
    },
  },
}
