//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showModal: Boolean,
    youtubeUrl: String,
  },
  computed: {
    embeddedYoutubeUrl() {
      // Transform the YouTube URL into the embedded format
      return this.youtubeUrl.replace('watch?v=', 'embed/')
    },
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
}
