//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TransitionExpand',
  methods: {
    enter(element) {
      const width = window.getComputedStyle(element).width

      element.style.width = width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = window.getComputedStyle(element).height

      element.style.width = null
      element.style.position = null
      element.style.visibility = null
      element.style.height = 0

      window.requestAnimationFrame(() => {
        element.style.height = height
      })
    },

    afterEnter(element) {
      element.style.height = 'auto'
    },

    leave(element) {
      element.style.height = window.getComputedStyle(element).height

      window.requestAnimationFrame(() => {
        element.style.height = 0
      })
    },
  },
}
