//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getMenusWithArea from '~/gql/getMenusWithArea'
import Areas from '~/utils/areas'
export default {
  data() {
    return {
      menuItems: [],
      area: Areas.HEADER,
      activeSubmenu: null,
      subMenuListensToMouseEvents: false,
    }
  },
  async fetch() {
    const response = await this.$graphcms.request(getMenusWithArea, {
      area: [this.area],
      lang: [this.$i18n.locale, this.$i18n.defaultLocale],
    })
    this.menuItems = response?.sectionMenus.sort(function (a, b) {
      return a.priority - b.priority
    })
  },

  watch: {
    '$i18n.locale'() {
      this.$fetch()
    },
    $route() {
      this.subMenuListensToMouseEvents = false
    },
  },
  methods: {
    openSubmenu(id) {
      this.subMenuListensToMouseEvents = true
      this.activeSubmenu = id
    },
    closeSubmenu() {
      this.activeSubmenu = null
    },
    getThemeColor(theme) {
      const themeData =
        this.$store.state.global.themes[theme] ??
        this.$store.state.global.themes.red
      return themeData.color
    },
  },
}
