//
//
//
//
//
//

export default {
  computed: {
    drawer() {
      return this.$store.state.global.drawer ?? null
    },
  },
}
